<template>
  <div class="card bg-primary text-white">
    <div class="card-body">
      <div class="pt-5 pb-5 pl-5 pr-5 text-center flex-grow-1">
        <i class="fe fe-hard-drive font-size-80 mb-3 d-block"></i>
        <div class="font-weight-bold font-size-36">Premium</div>
        <div class="font-weight-bold font-size-48 mb-3">
          $99 <span class="align-text-top font-size-28">/mo</span>
        </div>
        <ul class="list-unstyled font-size-18 mb-5">
          <li>50GB of Bandwidth</li>
          <li>1GB Max File Size</li>
          <li>3GHZ CPU</li>
          <li>4096MB Memory</li>
          <li>8 GB Storage</li>
        </ul>
        <a class="btn btn-white width-100" href="javascript: void(0);">Get Access</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitList21v2',
}
</script>
