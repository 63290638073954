<template>
  <div class="row">
    <div class="col-xl-4 col-lg-12">
      <div>
        <h2 class="badge-example">List / 1</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-1 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 5</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-5 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 8</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-8 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 11</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-11 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 14</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-14 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 17</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-17 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 20</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-20 />
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-12">
      <div>
        <h2 class="badge-example">List / 2</h2>
        <div class="card">
          <kit-list-2 />
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 6</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-6 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 9</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-9 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 12</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-12 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 15</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-15 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 19</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-19 />
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-lg-12">
      <div>
        <h2 class="badge-example">List / 3</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-3 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 4</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-4 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 7</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-7 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 10</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-10 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 13</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-13 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 16</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-16 />
          </div>
        </div>
      </div>
      <div>
        <h2 class="badge-example">List / 18</h2>
        <div class="card">
          <div class="card-body">
            <kit-list-18 />
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <h2 class="badge-example">List / 21</h2>
      <kit-list-21 />
    </div>
    <div class="col-lg-4">
      <h2 class="badge-example">List / 21-1</h2>
      <kit-list-21v1 />
    </div>
    <div class="col-lg-4">
      <h2 class="badge-example">List / 22-2</h2>
      <kit-list-21v2 />
    </div>
  </div>
</template>

<script>
import KitList1 from '@/components/kit/widgets/Lists/1'
import KitList2 from '@/components/kit/widgets/Lists/2'
import KitList3 from '@/components/kit/widgets/Lists/3'
import KitList4 from '@/components/kit/widgets/Lists/4'
import KitList5 from '@/components/kit/widgets/Lists/5'
import KitList6 from '@/components/kit/widgets/Lists/6'
import KitList7 from '@/components/kit/widgets/Lists/7'
import KitList8 from '@/components/kit/widgets/Lists/8'
import KitList9 from '@/components/kit/widgets/Lists/9'
import KitList10 from '@/components/kit/widgets/Lists/10'
import KitList11 from '@/components/kit/widgets/Lists/11'
import KitList12 from '@/components/kit/widgets/Lists/12'
import KitList13 from '@/components/kit/widgets/Lists/13'
import KitList14 from '@/components/kit/widgets/Lists/14'
import KitList15 from '@/components/kit/widgets/Lists/15'
import KitList16 from '@/components/kit/widgets/Lists/16'
import KitList17 from '@/components/kit/widgets/Lists/17'
import KitList18 from '@/components/kit/widgets/Lists/18'
import KitList19 from '@/components/kit/widgets/Lists/19'
import KitList20 from '@/components/kit/widgets/Lists/20'
import KitList21 from '@/components/kit/widgets/Lists/21'
import KitList21v1 from '@/components/kit/widgets/Lists/21v1'
import KitList21v2 from '@/components/kit/widgets/Lists/21v2'

export default {
  components: {
    KitList1,
    KitList2,
    KitList3,
    KitList4,
    KitList5,
    KitList6,
    KitList7,
    KitList8,
    KitList9,
    KitList10,
    KitList11,
    KitList12,
    KitList13,
    KitList14,
    KitList15,
    KitList16,
    KitList17,
    KitList18,
    KitList19,
    KitList20,
    KitList21,
    KitList21v1,
    KitList21v2,
  },
}
</script>
