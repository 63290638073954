<template>
  <div>
    <ul class="list-unstyled">
      <li :class="$style.item">
        <a href="javascript: void(0);" :class="$style.itemLink">
          <div :class="$style.itemMeta" class="font-weight-bold text-danger">
            2:28
            <br/>
            PM
          </div>
          <div class="mr-3">
            <div>Payment Received</div>
            <div class="text-muted">Mary has approved your quote.</div>
          </div>
          <div :class="$style.itemAction">
            <span/>
            <span/>
          </div>
        </a>
      </li>
      <li :class="$style.item">
        <a href="javascript: void(0);" :class="$style.itemLink">
          <div :class="$style.itemMeta" class="font-weight-bold text-blue">
            1:02
            <br/>
            AM
          </div>
          <div class="mr-3">
            <div>Account Activated</div>
            <div class="text-muted">Mary has approved your quote.</div>
          </div>
          <div :class="$style.itemAction">
            <span/>
            <span/>
          </div>
        </a>
      </li>
      <li :class="$style.item">
        <a href="javascript: void(0);" :class="$style.itemLink">
          <div :class="$style.itemMeta" class="font-weight-bold">
            2:28
            <br/>
            PM
          </div>
          <div class="mr-3">
            <div>Payment Received</div>
            <div class="text-muted">Mary has approved your quote.</div>
          </div>
          <div :class="$style.itemAction">
            <span/>
            <span/>
          </div>
        </a>
      </li>
      <li :class="$style.item">
        <a href="javascript: void(0);" :class="$style.itemLink">
          <div :class="$style.itemMeta" class="font-weight-bold">
            2:28
            <br/>
            PM
          </div>
          <div class="mr-3">
            <div>Payment Received</div>
            <div class="text-muted">Mary has approved your quote.</div>
          </div>
          <div :class="$style.itemAction">
            <span/>
            <span/>
          </div>
        </a>
      </li>
      <li :class="$style.item">
        <a href="javascript: void(0);" :class="$style.itemLink">
          <div :class="$style.itemMeta" class="font-weight-bold">
            2:28
            <br/>
            PM
          </div>
          <div class="mr-3">
            <div>Payment Received</div>
            <div class="text-muted">Mary has approved your quote.</div>
          </div>
          <div :class="$style.itemAction">
            <span/>
            <span/>
          </div>
        </a>
      </li>
      <li :class="$style.item">
        <a href="javascript: void(0);" :class="$style.itemLink">
          <div :class="$style.itemMeta" class="font-weight-bold">
            2:28
            <br/>
            PM
          </div>
          <div class="mr-3">
            <div>Payment Received</div>
            <div class="text-muted">Mary has approved your quote.</div>
          </div>
          <div :class="$style.itemAction">
            <span/>
            <span/>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'KitList3',
}
</script>
<style lang="scss" module>
  @import './style.module.scss';
</style>
